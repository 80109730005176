import React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import * as eva from '@eva-design/eva';
import { ApplicationProvider as KittenProvider, IconRegistry } from '@ui-kitten/components';
import 'react-native-gesture-handler';
import { paperNativeTheme, uiKittenTheme } from './src/core/theme';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import App from './src';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/store';
import { setAuthToken } from 'src/core/cache';
import { keycloak } from './src/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/translations/i18n.js';

export default () => {
  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={({ token }) => {
          setAuthToken(token);
        }}
        onEvent={(event, error) => {
          if (event === 'onInitError') {
            console.log('App: Init keycloak error: Check your Keycloak settings.');
          }
        }}>
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={client}>
            <KittenProvider {...eva} theme={{ ...eva.light, ...uiKittenTheme }}>
              <PaperProvider theme={paperNativeTheme}>
                <ActionSheetProvider>
                  <App />
                </ActionSheetProvider>
              </PaperProvider>
            </KittenProvider>
          </ApolloProvider>
        </I18nextProvider>
      </ReactKeycloakProvider>
    </>
  );
};
