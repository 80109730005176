import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Modal, Text } from 'react-native-paper';
import { Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { List, ListItem } from '@ui-kitten/components';
import { format } from 'date-fns';
import Table from '../../components/Table';
import Background from '../../components/Background';
import Dialogue from '../../components/Dialogue';
import {
  CANCELLABLE_PICKING_TASK_STATUS,
  PICKING_TASK_STATUS_CODE,
  PICKING_TASK_STATUS_MAP,
} from '@ezom/library/lib/cjs/constants';
import exportCsv from '../../core/csv';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsFocused } from '@react-navigation/core';
import { pickingTaskTableStore, warehouseAccountStore, warehousesStore } from '../../store';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { all } from 'ramda';
import PickingTaskTableControl from '../../components/PickingTaskTableControl';
import PickingTaskSearchForm from '../../components/PickingTaskSearchForm';
import { unifiedAlert, unifiedConfirm } from '../../core/utils/utils';

const getId = (item) => item.id;
const selectedTasks = (tasks, selectedIds) => tasks.filter((task) => selectedIds.has(getId(task)));
const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

export default observer(({ navigation }) => {
  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      pickingTaskTableStore.fetchItems();
    }
  }, [isFocused]);
  return (
    <Background fullWidth={true}>
      <PickingTaskTableControl
        filterParams={pickingTaskTableStore.filterParams}
        initialSearchTerm={pickingTaskTableStore.searchTerm}
        indicators={getIndicators(pickingTaskTableStore.filterParams)}
        onSearchTermChange={pickingTaskTableStore.setSearchTerm}
        onPressAdvancedSearch={pickingTaskTableStore.openSearch}
        onFilterClear={() => {
          pickingTaskTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          pickingTaskTableStore.setFilterParams({
            ...pickingTaskTableStore.filterParams,
            ...params,
          });
        }}
        disableSearch={pickingTaskTableStore.loading}
        requireButtonClickToSearch={pickingTaskTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: pickingTaskTableStore.error,
            indeterminate: pickingTaskTableStore.loading,
          }}>
          <Table
            styleByKey={{ id: { minWidth: 180 } }}
            items={pickingTaskTableStore.items}
            getId={getId}
            displayKeys={pickingTaskTableStore.DISPLAY_KEYS}
            sort="single"
            defaultSortOption={pickingTaskTableStore.sortOption}
            onSortChange={pickingTaskTableStore.setSortOption}
            heightOffset={300}
            titleByKey={pickingTaskTableStore.TITLE_BY_KEY}
            formatterByKey={pickingTaskTableStore.FORMATTER_BY_KEY}
            rowOnClick={(item) =>
              navigation.navigate('PickingTaskDetail', {
                id: item.id,
                warehouseAccountId: item.warehouseAccountId,
              })
            }
            isFabVisible={isFocused}
            currentPage={pickingTaskTableStore.currentPage}
            onPageChange={(page) => {
              pickingTaskTableStore.setCurrentPage(page);
            }}
            totalItemNum={pickingTaskTableStore.total}
            itemsPerPage={pickingTaskTableStore.pageSize}
            setPageSize={pickingTaskTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'magnify',
                  label: 'Search picking tasks',
                  onPress: pickingTaskTableStore.openSearch,
                });
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all',
                  onPress: () => {
                    const ics = pickingTaskTableStore.items.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = ics?.length > 0 ? Object.keys(ics[0]) : [];
                    exportCsv(ics, csvHeaders, 'picking_tasks.csv');
                  },
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: `Export selected ${selectedIds.size > 1 ? 'tasks' : 'task'}`,
                  onPress: () => {
                    const ics = selectedTasks(pickingTaskTableStore.items, selectedIds);
                    const exportedIcs = ics.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = exportedIcs?.length > 0 ? Object.keys(exportedIcs[0]) : [];
                    exportCsv(exportedIcs, csvHeaders, 'picking_tasks.csv');
                  },
                });
                if (
                  all(
                    (item) => CANCELLABLE_PICKING_TASK_STATUS.includes(item.status),
                    selectedTasks(pickingTaskTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: `Cancel selected ${selectedIds.size > 1 ? 'tasks' : 'task'}`,
                    onPress: () => {
                      pickingTaskTableStore.ordersToCancel = selectedIds;
                      pickingTaskTableStore.openCancelDialogue();
                    },
                  });
                }

                if (
                  all(
                    (item) => PICKING_TASK_STATUS_CODE.Packed === item.status,
                    selectedTasks(pickingTaskTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cube-send',
                    label: `Submit manifest for ${selectedIds.size > 1 ? 'tasks' : 'task'}`,
                    onPress: () => {
                      unifiedConfirm(
                        'Submit manifest',
                        'This cannot be undone. Are you sure?',
                        async () => {
                          try {
                            await Promise.all(
                              selectedTasks(pickingTaskTableStore.items, selectedIds).map((task) =>
                                pickingTaskTableStore.submitPickingTask(
                                  task.warehouseAccountId,
                                  task.id,
                                ),
                              ),
                            );
                            await pickingTaskTableStore.fetchItems();
                          } catch (e) {
                            unifiedAlert(e.message);
                          }
                        },
                      );
                    },
                  });
                }
              }

              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>
      <Portal>
        <Modal
          visible={pickingTaskTableStore.searchOpen}
          onDismiss={pickingTaskTableStore.closeSearch}
          contentContainerStyle={styles.modalStyle}>
          <PickingTaskSearchForm
            searchParams={pickingTaskTableStore.filterParams}
            items={pickingTaskTableStore.items}
            onDismiss={pickingTaskTableStore.closeSearch}
            onSubmit={(filterParams) => {
              pickingTaskTableStore.setFilterParams(filterParams);
              pickingTaskTableStore.closeSearch();
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={pickingTaskTableStore.cancelDialogueOpen}
          onDismiss={pickingTaskTableStore.closeCancelDialogue}
          contentContainerStyle={styles.modalStyle}>
          <Dialogue
            onSubmit={async () => {
              try {
                await Promise.all(
                  selectedTasks(
                    pickingTaskTableStore.items,
                    pickingTaskTableStore.ordersToCancel,
                  ).map((task) =>
                    pickingTaskTableStore.cancelItem(task.warehouseAccountId, task.id),
                  ),
                );
              } catch (e) {
                unifiedAlert(e.message);
              }
              setTimeout(() => pickingTaskTableStore.fetchItems(), 2000);
              pickingTaskTableStore.closeCancelDialogue();
            }}
            onDismiss={pickingTaskTableStore.closeCancelDialogue}>
            <Text>Are you sure you want to cancel the picking tasks?</Text>
            <List
              data={selectedTasks(
                pickingTaskTableStore.items,
                pickingTaskTableStore.ordersToCancel,
              )}
              renderItem={({ item }) => <ListItem title={`${item.id}`} />}
            />
          </Dialogue>
        </Modal>
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', margin: 20 },
});

const getIndicators = (params) => {
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(`consignment no.: ${params.consignmentNumber}`);
  } else {
    if (params.refNumber) {
      indicators.push(`ref. no.: ${params.refNumber}`);
    } else {
      if (params.status) {
        indicators.push(`status: ${PICKING_TASK_STATUS_MAP[params.status]}`);
      }

      if (params.warehouse_code) {
        indicators.push(
          `warehouse: ${warehousesStore.getWarehouseNameEn(
            params.warehouse_code,
            warehouseAccountStore.selectedWarehouseAccount,
          )}`,
        );
      }

      if (params.create_time_start && params.create_time_end) {
        indicators.push(
          `orders from ${dateFormatter(params.create_time_start)} to ${dateFormatter(
            params.create_time_end,
          )}`,
        );
      } else if (params.create_time_start) {
        indicators.push(`orders from ${dateFormatter(params.create_time_start)}`);
      } else if (params.create_time_end) {
        indicators.push(`orders by ${dateFormatter(params.create_time_end)}`);
      }
    }
  }

  return indicators;
};
