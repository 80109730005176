import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from 'src/core/cache';
import DebounceLink from 'apollo-link-debounce';
import { SkuMappingStore } from './SkuMappingStore';
import { SkuMappingTableStore } from './SkuMappingTableStore';
import { InventoryStore } from './InventoryStore';
import { OrderTableStore } from './OrderTableStore';
import { OrderCancelStore } from './OrderCancelStore';
import { SkuTableStore } from './SkuTableStore';
import { OutboundOrderStore } from './OutboundOrderStore';
import { InboundOrderStore } from './InboundOrderStore';
import { OutboundOrderTableStore } from './OutboundOrderTableStore';
import { InboundOrderTableStore } from './InboundOrderTableStore';
import { InventoryStatisticsStore } from './InventoryStatisticsStore';
import { StoreStore } from './StoreStore';
import { SalesStatisticsStore } from './SalesStatisticsStore';
import { RestockListStore } from './RestockListStore';
import { WarehousesStore } from './WarehousesStore';
import { WarehouseAccountStore } from './WarehouseAccountStore';
import { CountryStateCityCurrencyStore } from './CountryStateCityCurrencyStore';
import { CourierStore } from './CourierStore';
import { TransferOrderStore } from './TransferOrderStore';
import { TransferOrderTableStore } from './TransferOrderTableStore';
import { CourierShipmentTableStore } from './CourierShipmentTableStore';
import { CourierShipmentStore } from './CourierShipmentStore';
import { PickingTaskTableStore } from './PickingTaskTableStore';
import { PackagingMaterialTableStore } from './PackagingMaterialTableStore';
import { OrganisationStore } from './OrganisationStore';
import { PrinterSettingTableStore } from './PrinterTableStore';
import { AddressBookStore } from './AddressBookStore';
import { AddressBookTableStore } from './AddressBookTableStore';
import { XeroStore } from './XeroStore';
import { LanguageStore } from './LanguageStore';

const httpLink = new BatchHttpLink({
  uri: process.env.API_URL || 'http://localhost:4000/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const DEFAULT_DEBOUNCE_TIMEOUT = 400;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

export const client = new ApolloClient({
  link: from([debounceLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const organisationStore = new OrganisationStore(client);

export const storeStore = new StoreStore(client);

export const courierStore = new CourierStore(client, storeStore);

export const warehouseAccountStore = new WarehouseAccountStore(client);

export const warehousesStore = new WarehousesStore(client, warehouseAccountStore, storeStore);

export const skuMappingStore = new SkuMappingStore(
  client,
  storeStore,
  warehousesStore,
  warehouseAccountStore,
);

export const inventoryStore = new InventoryStore(client, warehouseAccountStore);

export const outboundOrderStore = new OutboundOrderStore(client, warehouseAccountStore);

export const inboundOrderStore = new InboundOrderStore(client, warehouseAccountStore);

export const transferOrderStore = new TransferOrderStore(client, warehouseAccountStore);

export const skuMappingTableStore = new SkuMappingTableStore(skuMappingStore);

export const salesStatisticsStore = new SalesStatisticsStore(
  client,
  storeStore,
  warehouseAccountStore,
);

export const orderTableStore = new OrderTableStore(client, storeStore, salesStatisticsStore);

export const orderCancelStore = new OrderCancelStore(client);

export const skuTableStore = new SkuTableStore(client, inventoryStore, warehouseAccountStore);

export const printerSettingStore = new PrinterSettingTableStore(client, warehouseAccountStore);

export const outboundOrderTableStore = new OutboundOrderTableStore(
  client,
  outboundOrderStore,
  warehousesStore,
  warehouseAccountStore,
  courierStore,
  organisationStore,
);

export const inboundOrderTableStore = new InboundOrderTableStore(
  client,
  inboundOrderStore,
  warehousesStore,
  warehouseAccountStore,
);

export const transferOrderTableStore = new TransferOrderTableStore(
  client,
  transferOrderStore,
  warehousesStore,
  warehouseAccountStore,
);

export const inventoryStatisticsStore = new InventoryStatisticsStore(
  client,
  outboundOrderStore,
  inventoryStore,
  skuMappingStore,
);

export const pickingTaskTableStore = new PickingTaskTableStore(
  client,
  warehousesStore,
  warehouseAccountStore,
);

export const packagingMaterialTableStore = new PackagingMaterialTableStore(
  client,
  warehousesStore,
  warehouseAccountStore,
  organisationStore,
);

export const courierShipmentStore = new CourierShipmentStore(
  client,
  warehouseAccountStore,
  courierStore,
);

export const courierShipmentTableStore = new CourierShipmentTableStore(
  client,
  courierShipmentStore,
  warehouseAccountStore,
);

export const restockListStore = new RestockListStore(client, storeStore, salesStatisticsStore);

export const countryStateCityCurrencyStore = new CountryStateCityCurrencyStore(client);

export const addressBookStore = new AddressBookStore(client);
export const addressBookTableStore = new AddressBookTableStore(client);

export const xeroStore = new XeroStore(client, warehouseAccountStore);

export const languageStore = new LanguageStore();
