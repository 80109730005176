import i18n from 'i18next';
import en from './en.json';
import zh from './zh.json';

i18n.init({
  resources: {
    en: { translation: en },
    zh: { translation: zh },
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
