import React, { useCallback, useEffect } from 'react';
import Background from '../../components/Background';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { OrgCard } from './OrgCard';
import { Layout, ModalService, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { organisationStore, warehouseAccountStore } from '../../store';
import Button from '../../components/Button';
import { unifiedAlert, useIsAccountManager } from '../../core/utils/utils';
import { Formik } from 'formik';
import { View } from 'react-native';
import Select from '../../components/Select';
import { StyleSheet } from 'react-native';
import * as Yup from 'yup';
import { TextInputField } from '../../components/input/TextInputField';
import { useRoute } from '@react-navigation/core';
import { ezTheme, paperNativeTheme } from '../../core/theme';

const renderAddOrgModalContentElement = (warehouseAccounts, onSubmit, onCancel) => {
  const masterWarehouseAccounts = warehouseAccounts.filter((s) => s.isMasterAccount);

  return (
    <View style={styles.modalContainer}>
      <Formik
        initialValues={{
          orgId: '',
          warehouseAccountId:
            masterWarehouseAccounts.length === 1 ? masterWarehouseAccounts[0].id : '',
        }}
        isInitialValid={false}
        validationSchema={Yup.object({
          orgId: Yup.string().test(
            'is-valid-hex',
            'Not a valid organisation ID',
            (value) => !Number.isNaN(Number(`0x${value}`, 16)),
          ),
          warehouseAccountId: Yup.number().required(),
        })}
        onSubmit={(values) => {
          onSubmit(parseInt(values.orgId, 16), values.warehouseAccountId);
        }}>
        {({ handleSubmit, values, ...props }) => (
          <>
            <Select
              label="Warehouse account"
              value={
                masterWarehouseAccounts.find(({ id }) => id === values.warehouseAccountId)?.name
              }
              error={props.errors.warehouseAccountId}
              touched={props.touched.warehouseAccountId}
              options={masterWarehouseAccounts.map((s) => ({
                key: s.id,
                val: s.name,
              }))}
              onChange={(val) => {
                props.setFieldValue(`warehouseAccountId`, val.key);
              }}
              onBlur={() => props.setFieldTouched(`warehouseAccountId`, true)}
              style={styles.input}
            />
            <TextInputField label="Organisation ID" name="orgId" style={styles.field} {...props} />
            <Layout style={styles.buttonContainer}>
              <Button
                onPress={handleSubmit}
                style={styles.button}
                color={paperNativeTheme.colors.primary}
                disabled={!props.isValid}>
                Submit
              </Button>
              <Button
                onPress={onCancel}
                style={styles.button}
                color={paperNativeTheme.colors.warning}>
                Cancel
              </Button>
            </Layout>
          </>
        )}
      </Formik>
    </View>
  );
};

const renderDeleteOrgModalContentElement = (warehouseAccounts, orgToDelete, onSubmit, onCancel) => {
  const masterWarehouseAccounts = warehouseAccounts.filter((s) => s.isMasterAccount);
  const confirmationTextValid = `delete ${orgToDelete?.name}`;
  return (
    <View style={styles.modalContainer}>
      <Formik
        initialValues={{
          warehouseAccountId:
            masterWarehouseAccounts.length === 1 ? masterWarehouseAccounts[0].id : '',
          confirmationText: '',
        }}
        isInitialValid={false}
        validationSchema={Yup.object({
          warehouseAccountId: Yup.number().required(),
          confirmationText: Yup.string().required().equals([confirmationTextValid], 'Invalid'),
        })}
        onSubmit={(values) => {
          onSubmit(values.warehouseAccountId);
        }}>
        {({ handleSubmit, values, ...props }) => {
          return (
            <>
              <Select
                label="Warehouse account"
                value={
                  masterWarehouseAccounts.find(({ id }) => id === values.warehouseAccountId)?.name
                }
                error={props.errors.warehouseAccountId}
                touched={props.touched.warehouseAccountId}
                options={masterWarehouseAccounts.map((s) => ({
                  key: s.id,
                  val: s.name,
                }))}
                onChange={(val) => {
                  props.setFieldValue(`warehouseAccountId`, val.key);
                }}
                onBlur={() => props.setFieldTouched(`warehouseAccountId`, true)}
                style={styles.input}
              />
              <Text status="danger">
                Warning: users from organsation {orgToDelete?.name} will no longer be able to access
                warehouse related data.
                <br />
                Are your sure you want to delete this organisation from warehouse account{' '}
                {masterWarehouseAccounts.find(({ id }) => id === values.warehouseAccountId)?.name}?
                <br />
                <br />
                Type{' '}
                <strong>
                  <i>{confirmationTextValid}</i>
                </strong>{' '}
                to confirm
              </Text>
              <TextInputField name="confirmationText" style={styles.field} {...props} />
              <Layout style={styles.buttonContainer}>
                <Button
                  onPress={handleSubmit}
                  style={styles.button}
                  color={paperNativeTheme.colors.primary}
                  disabled={!props.isValid}>
                  Submit
                </Button>
                <Button
                  onPress={onCancel}
                  style={styles.button}
                  color={paperNativeTheme.colors.warning}>
                  Cancel
                </Button>
              </Layout>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export const OrgListScreen = React.memo(
  observer(() => {
    const isAccountManager = useIsAccountManager();
    const warehouseAccounts = warehouseAccountStore.warehouseAccounts;
    let modalID = '';
    const showAddOrgModal = useCallback(() => {
      const contentElement = renderAddOrgModalContentElement(
        warehouseAccounts,
        async (organisationId, masterWarehouseAccountId) => {
          try {
            await warehouseAccountStore.setOrganisationMasterWarehouseAccount(
              organisationId,
              masterWarehouseAccountId,
            );
            await organisationStore.fetchItems();
            ModalService.hide(modalID);
          } catch (e) {
            unifiedAlert(e.message);
          }
        },
        () => ModalService.hide(modalID),
      );
      modalID = ModalService.show(contentElement, {
        onBackdropPress: hideModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    });
    const showDeleteOrgModal = useCallback((org) => {
      const contentElement = renderDeleteOrgModalContentElement(
        warehouseAccounts,
        org,
        async (masterWarehouseAccountId) => {
          try {
            await warehouseAccountStore.deleteOrganisationMasterWarehouseAccount(
              org.id,
              masterWarehouseAccountId,
            );
            await organisationStore.fetchItems();
            ModalService.hide(modalID);
          } catch (e) {
            unifiedAlert(e.message);
          }
        },
        () => ModalService.hide(modalID),
      );
      modalID = ModalService.show(contentElement, {
        onBackdropPress: hideModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    });
    const hideModal = useCallback(() => {
      ModalService.hide(modalID);
    });

    return (
      <Background fullWidth={true}>
        <Layout style={{ overflowY: 'auto', height: 'calc(100vh - 125px)' }}>
          <UIStatusWrapper
            status={
              new UIStatus(
                organisationStore.loading,
                organisationStore.error,
                organisationStore.items && organisationStore.items.length === 0,
              )
            }>
            {isAccountManager ? (
              <Button icon="account-plus-outline" mode="outlined" onPress={showAddOrgModal}>
                Add a client
              </Button>
            ) : null}
            {organisationStore.items.map((org) => (
              <OrgCard
                org={org}
                onSaveRates={() => organisationStore.fetchItems()}
                onDeleteOrg={() => showDeleteOrgModal(org)}
              />
            ))}
          </UIStatusWrapper>
        </Layout>
      </Background>
    );
  }),
);

const styles = StyleSheet.create({
  input: {
    flex: 1,
    flexDirection: 'row',
    minWidth: '20rem',
  },
  button: {
    maxHeight: '2rem',
    maxWidth: '10rem',
  },
  buttonContainer: {
    marginTop: '0.4em',
    gap: '1rem',
    flexDirection: 'row-reverse ',
    justifyContent: 'space-between',
    display: 'flex',
  },
  modalContainer: {
    padding: '1rem',
    backgroundColor: 'white',
    gap: '1rem',
    width: 'fit-content',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
  },
});
