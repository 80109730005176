import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { unifiedAlert } from '../core/utils/utils';
import { getShipmentValidationSchema } from '../components/CreateShipmentForm';
import { isValid, parse } from 'date-fns';

const PRINT_SHIPMENT_ORDERS_QUERY = gql`
  query printShippingLabels(
    $warehouseAccountId: ID!
    $orderIds: [ID!]!
    $clientTimezoneOffset: Int!
  ) {
    printShippingLabels(
      warehouseAccountId: $warehouseAccountId
      orderIds: $orderIds
      clientTimezoneOffset: $clientTimezoneOffset
    )
  }
`;

const PRINT_SHIPMENT_MANIFESTS_QUERY = gql`
  query printShippingManifests($warehouseAccountId: ID!, $orderIds: [ID!]!) {
    printShippingManifests(warehouseAccountId: $warehouseAccountId, orderIds: $orderIds)
  }
`;

const CREATE_SHIPMENT = gql`
  mutation createShipmentOrder($shipmentOrder: ShipmentOrderInput!, $warehouseAccountId: ID!) {
    createShipmentOrder(shipmentOrder: $shipmentOrder, warehouseAccountId: $warehouseAccountId) {
      id
    }
  }
`;

const COPY_SHIPMENT = gql`
  mutation copyShipmentOrder($warehouseAccountId: ID!, $shipmentOrderId: ID!) {
    copyShipmentOrder(warehouseAccountId: $warehouseAccountId, shipmentOrderId: $shipmentOrderId) {
      id
    }
  }
`;

const CANCEL_SHIPMENT = gql`
  mutation cancelShipmentOrder($warehouseAccountId: ID!, $id: ID!) {
    cancelShipmentOrder(warehouseAccountId: $warehouseAccountId, id: $id) {
      id
    }
  }
`;

const SUBMIT_SHIPMENT = gql`
  mutation submitShipmentOrder($id: ID!, $warehouseAccountId: ID!) {
    submitShipmentOrder(id: $id, warehouseAccountId: $warehouseAccountId) {
      id
      createTransaction {
        amount
      }
    }
  }
`;

const TRACK_SHIPMENT_ORDER = gql`
  query trackShipmentOrder($warehouseAccountId: ID!, $id: ID!) {
    trackShipmentOrder(warehouseAccountId: $warehouseAccountId, id: $id) {
      id
      error
      trackingNumber
      trackingEvents {
        date
        location
        status
        courier
        pod
      }
    }
  }
`;

const SHIPMENT_ORDER = gql`
  query shipmentOrder($warehouseAccountId: ID!, $ids: [ID!]!) {
    listShipmentOrders(warehouseAccountId: $warehouseAccountId, ids: $ids) {
      shipmentOrders {
        id
        ref_no
        status
        total_volume
        total_weight
        sales_no
        create_time
        update_time
        note
        error
        pickup_country
        pickup_state
        pickup_city
        pickup_district
        pickup_post_code
        pickup_street
        pickup_house_number
        pickup_company
        pickup_name
        pickup_phone
        pickup_email
        receiver_country
        receiver_state
        receiver_city
        receiver_district
        receiver_post_code
        receiver_street
        receiver_house_number
        receiver_company
        receiver_name
        receiver_phone
        receiver_email
        shipments {
          id
          courierConsignmentId
          tracking_number
          courier
          logistics_provider
          items {
            weight
            length
            width
            height
            sku
            qty
          }
        }
        transactions {
          id
          amount
          remark
          remarkExtra
          status
        }
      }
      total
      page_no
      page_size
    }
  }
`;

function parseDate(dateString) {
  const tmp = parse(dateString, 'dd/MM/yyyy HH:mm', new Date());
  return isValid(tmp) ? tmp.toISOString() : undefined;
}
const mapShipmentOrder = (o) => {
  const itemKeys = Object.keys(o).filter((key) => key.startsWith('Description') && !!o[key]);
  const items = itemKeys.map((key) => {
    const index = key.replace('Description', '');
    const qtyLabel = `QTY${index}`;
    const weightLabel = `Weight (kg)${index}`;
    const lengthLabel = `Length (cm)${index}`;
    const widthLabel = `Width (cm)${index}`;
    const heightLabel = `Height (cm)${index}`;
    const exportDeclare = `Export Declaration${index}`;
    const exportDeclareValue = `Export Declare Value (USD)${index}`;
    const exportDeclareHscode = `HS-Code${index}`;
    const countryOfOrigin = `Country of Origin${index}`;
    return {
      description: o[key],
      qty: o[qtyLabel],
      weight: o[weightLabel],
      length: o[lengthLabel],
      width: o[widthLabel],
      height: o[heightLabel],
      exportInfo:
        o[exportDeclare] && o[exportDeclareValue] && o[exportDeclareHscode] && o[countryOfOrigin]
          ? [
              {
                declare: o[exportDeclare],
                declareValue: o[exportDeclareValue],
                hscode: o[exportDeclareHscode] && new String(o[exportDeclareHscode]),
                countryOfOrigin: o[countryOfOrigin],
                // batach upload only allow 1 declare item per package
                qty: 1,
                weight: o[weightLabel],
              },
            ]
          : undefined,
    };
  });
  return {
    ref_no: o['Ref No.'] && `${o['Ref No.']}`,
    sales_no: o['Sales No.'] && `${o['Sales No.']}`,
    note: o['Note'],
    pickupAddress: {
      country: o['Pickup Country'],
      state: o['Pickup State'],
      city: o['Pickup City'],
      district: o['Pickup District'],
      post_code: o['Pickup Postcode'] && `${o['Pickup Postcode']}`,
      street: o['Pickup Street'],
      house_number: o['Pickup House Number'] && `${o['Pickup House Number']}`,
      company: o['Pickup Company Name'],
      name: o['Pickup Contact Name'] && `${o['Pickup Contact Name']}`,
      phone: o['Pickup Contact Phone Number'] && `${o['Pickup Contact Phone Number']}`,
      email: o['Pickup Contact Email'],
    },
    receiverAddress: {
      country: o['Receiver Country'],
      state: o['Receiver State'],
      city: o['Receiver City'],
      district: o['Receiver District'],
      post_code: o['Receiver Postcode'] && `${o['Receiver Postcode']}`,
      street: o['Receiver Street'],
      house_number: o['Receiver House Number'] && `${o['Receiver House Number']}`,
      company: o['Receiver Company Name'],
      name: o['Receiver Name'] && `${o['Receiver Name']}`,
      phone: o['Receiver Phone Number'] && `${o['Receiver Phone Number']}`,
      email: o['Receiver Email'],
    },
    shipment: {
      courier: o['Courier'],
      items,
      deliveryWindowEnabled: `${o['Delivery Window Enabled']}`.toLowerCase() === 'y' ? true : false,
      deliveryWindowStart: o['Delivery Window Start'] && parseDate(`${o['Delivery Window Start']}`),
      deliveryWindowEnd: o['Delivery Window End'] && parseDate(`${o['Delivery Window End']}`),
      tailgate: `${o['Tailgate']}`.toLowerCase() === 'y' ? true : false,
      handUnload: `${o['Hand Unload']}`.toLowerCase() === 'y' ? true : false,
      dangerousGoods: `${o['Dangerous Goods']}`.toLowerCase() === 'y' ? true : false,
    },
  };
};

export class CourierShipmentStore {
  cachedItems = null;
  loading = true;
  error = null;

  constructor(client, warehouseAccountStore, courierStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;
    this.courierStore = courierStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        addItems: flow,
        cancelItem: flow,
        submitItem: flow,
        trackShipmentOrder: flow,
        getShipmentOrder: flow,
        printLabels: flow,
        validateCsvUploadedShipmentOrders: flow,
        client: false,
        warehouseAccountStore: false,
        courierStore: false,
      },
      { autoBind: true },
    );
  }

  *addItem(warehouseAccountId, shipmentOrder) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CREATE_SHIPMENT,
        variables: {
          shipmentOrder,
          warehouseAccountId,
        },
      });
      return data.createShipmentOrder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *copyItem(warehouseAccountId, shipmentOrderId) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: COPY_SHIPMENT,
        variables: {
          warehouseAccountId,
          shipmentOrderId,
        },
      });
      unifiedAlert('Copy courier shipment successfully.');
      return data.copyShipmentOrder;
    } catch (error) {
      unifiedAlert(error);
    } finally {
      this.loading = false;
    }
  }

  *cancelItem(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CANCEL_SHIPMENT,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.cancelShipmentOrders;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *submitItem(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: SUBMIT_SHIPMENT,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.submitShipmentOrder;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *trackShipmentOrder(warehouseAccountId, id) {
    try {
      const { data } = yield this.client.mutate({
        mutation: TRACK_SHIPMENT_ORDER,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.trackShipmentOrder;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      throw error;
    }
  }

  *getShipmentOrder(warehouseAccountId, id) {
    try {
      const { data } = yield this.client.query({
        query: SHIPMENT_ORDER,
        variables: {
          warehouseAccountId,
          ids: [id],
        },
        fetchPolicy: 'no-cache',
      });
      return data?.listShipmentOrders?.shipmentOrders?.[0] || null;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      throw error;
    }
  }

  *printLabels(warehouseAccountId, ids) {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: PRINT_SHIPMENT_ORDERS_QUERY,
        variables: {
          warehouseAccountId,
          orderIds: ids,
          clientTimezoneOffset: new Date().getTimezoneOffset(),
        },
        fetchPolicy: 'network-only',
      });
      return data.printShippingLabels;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *printManifests(warehouseAccountId, ids) {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: PRINT_SHIPMENT_MANIFESTS_QUERY,
        variables: {
          warehouseAccountId,
          orderIds: ids,
        },
        fetchPolicy: 'network-only',
      });
      return data.printShippingManifests;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  static MapCsvUploadedShipmentOrders(data) {
    return data.map(mapShipmentOrder);
  }

  *validateCsvUploadedShipmentOrders(warehouseAccountId, data) {
    const errors = {};
    const courierList = yield this.courierStore.fetchShipmentOrderCourierList(warehouseAccountId);
    const schema = getShipmentValidationSchema(courierList);
    yield Promise.all(
      data.map((oc, i) =>
        schema
          .validate(oc, {
            abortEarly: false,
            context: true,
            strict: true,
          })
          .catch((error) => (errors[i] = error)),
      ),
    );
    return errors;
  }
}
