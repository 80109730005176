import React from 'react';
import { StyleSheet, View } from 'react-native';
import WarehouseDropdownSelection from './WarehouseDropdownSelection';
import { paperNativeTheme } from '../core/theme';

export const SecondaryTopBar = () => {
  return (
    <View style={styles.container}>
      <WarehouseDropdownSelection />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 8,
    backgroundColor: paperNativeTheme.colors.semiPrimary,
    flexDirection: 'row',
    justifyContent: 'flex-end', // This will align content to the right
    alignItems: 'center',
    borderTopColor: paperNativeTheme.colors.disabled,
  },
});

export default SecondaryTopBar;
