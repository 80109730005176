import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Icon,
  Layout,
  ListItem,
  ModalService,
  Text,
  Toggle,
} from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { StyleSheet, ScrollView, useWindowDimensions, View } from 'react-native';
import { OrderDispatchStore } from '../store/OrderDispatchStore';
import { reject, isEmpty } from 'ramda';
import { OrderTitle } from './OrderTitle';
import { ShippingProgressListModal } from './ShippingProgressListModal';
import {
  outboundOrderStore,
  orderTableStore,
  storeStore,
  warehouseAccountStore,
  countryStateCityCurrencyStore,
} from '../store';
import { UIStatusWrapper } from './ui-status';
import OrderSummary from './OrderSummary';
import { useNavigation } from '@react-navigation/native';
import CreateOutboundForm from './CreateOutboundForm';
import { WarehouseAccountSelectionModal } from './WarehouseAccountSelectionModal';
import { ezTheme } from '../core/theme';

export const styles = StyleSheet.create({
  shipAllButton: {
    marginRight: '1em',
  },
});

export default observer(
  ({ orderIdsToDispatch, goBack, setOrderIdsToDispatch, checkedItems, setCheckedItems }) => {
    const [splitAll, setSplitAll] = React.useState(false);
    const [warehouseSelectionVisible, setWarehouseSelectionVisible] = useState(false);
    const [ocVisible, setOcVisible] = useState(false);

    const windowHeight = useWindowDimensions().height;
    const orderDispatchStore = useMemo(
      () =>
        new OrderDispatchStore(
          orderTableStore.client,
          outboundOrderStore,
          storeStore,
          orderIdsToDispatch.map((o) => o.orderId),
          [...new Set(orderIdsToDispatch.map((o) => o.storeId))],
          warehouseAccountStore,
          countryStateCityCurrencyStore,
        ),
      [orderIdsToDispatch, outboundOrderStore, storeStore],
    );

    let modalID = '';

    const showModal = (order) => {
      const contentElement = renderModalContentElement(order);
      modalID = ModalService.show(contentElement, {
        onBackdropPress: hideModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    };

    const hideModal = () => {
      ModalService.hide(modalID);
    };

    const renderModalContentElement = (order) => {
      return (
        <View
          style={{
            width: 'fit-content',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '90%',
          }}>
          <OrderSummary order={order} onClose={hideModal} />
        </View>
      );
    };

    const removeOrderFulfillment = useCallback((order) => {
      setOrderIdsToDispatch(reject(({ orderId }) => order.id === orderId, orderIdsToDispatch));
      if (orderIdsToDispatch.length === 1) {
        goBack();
      }
    }, []);

    const onCheckedChange = useCallback((orderId) => {
      setCheckedItems((prev) =>
        prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId],
      );
    }, []);

    return (
      <View>
        <Title
          orderIdsToDispatch={orderIdsToDispatch}
          orderDispatchStore={orderDispatchStore}
          onSplitChecked={(isChecked) => {
            setSplitAll(isChecked);
            if (isChecked && !warehouseAccountStore.selectedWarehouseAccount) {
              setWarehouseSelectionVisible(true);
            } else {
              setOcVisible(false);
            }
          }}
        />
        <Divider />
        <UIStatusWrapper
          status={{
            indeterminate:
              orderDispatchStore.loadingOrders ||
              orderDispatchStore.loadingInventories ||
              orderDispatchStore.loadingSkuMappings,
            error: orderDispatchStore.error,
          }}>
          <ScrollView style={{ maxHeight: windowHeight - 150 }}>
            {orderDispatchStore.orders.length > 0
              ? orderDispatchStore.orders.map((order, index) => {
                  return (
                    <ListItem key={order.id}>
                      <Card style={{ width: 'calc(100%)' }} onPress={() => showModal(order)}>
                        <OrderTitle
                          disableAllActions={splitAll}
                          applyCourierToAll={(courier) =>
                            orderDispatchStore.setOutboundConsignmentSettings(
                              orderDispatchStore.outboundConsignmentSettings.map((s) => ({
                                ...s,
                                courier,
                              })),
                            )
                          }
                          applyWarehouseToAll={(warehouse) => {
                            orderDispatchStore.setOutboundConsignmentSettings(
                              orderDispatchStore.outboundConsignmentSettings.map((s) => ({
                                ...s,
                                warehouse: warehouse.warehouse_code,
                                warehouseAccountId: warehouse.warehouseAccountId,
                              })),
                            );
                          }}
                          orderNumber={order.number}
                          courier={orderDispatchStore.mergedOutboundConsignments[index].courier}
                          setCourier={(courier) =>
                            orderDispatchStore.setOutboundConsignmentSettingByIndex(index, {
                              ...orderDispatchStore.outboundConsignmentSettings[index],
                              courier,
                            })
                          }
                          warehouseCode={
                            orderDispatchStore.mergedOutboundConsignments[index].warehouse
                          }
                          warehouseAccountId={
                            orderDispatchStore.mergedOutboundConsignments[index].warehouseAccountId
                          }
                          setWarehouse={(warehouse) => {
                            orderDispatchStore.setOutboundConsignmentSettingByIndex(index, {
                              ...orderDispatchStore.outboundConsignmentSettings[index],
                              warehouse: warehouse.warehouse_code,
                              warehouseAccountId: warehouse.warehouseAccountId,
                            });
                          }}
                          storeId={order.storeId}
                          skuQuantity={orderDispatchStore.skuQuantity[index]}
                          validationError={orderDispatchStore.validationErrors[index]}
                          outOfStockWarehouseSkus={
                            orderDispatchStore.outOfStockWarehouseSkus[index]
                          }
                          unmappedStoreSkus={orderDispatchStore.unmappedStoreSkus[index]}
                          totalWeight={orderDispatchStore.totalWeight[index]}
                          totalVolume={orderDispatchStore.totalVolume[index]}
                          order={order}
                          checked={checkedItems.includes(order.id)}
                          onCheckedChange={() => onCheckedChange(order.id)}
                        />

                        {/*Only turn on this granular detailed form when auto-split is on*/}
                        {splitAll && orderDispatchStore && ocVisible && (
                          <Card>
                            <CreateOutboundForm
                              hideTitle={true}
                              orderId={order.id}
                              storeId={order.storeId}
                              onDismiss={undefined}
                              onSubmit={() => removeOrderFulfillment(order)}
                              skuQuantity={orderDispatchStore.skuQuantity?.[index]}
                              warehouseAccountId={
                                orderDispatchStore.getWarehouseAccountId(
                                  orderDispatchStore.storeStore.getStoreById(order.storeId),
                                  order,
                                )
                                  ? orderDispatchStore.getWarehouseAccountId(
                                      orderDispatchStore.storeStore.getStoreById(order.storeId),
                                      order,
                                    )
                                  : warehouseAccountStore.selectedWarehouseAccount &&
                                    warehouseAccountStore.selectedWarehouseAccount.id
                              }
                              checked={checkedItems.includes(order.id)}
                              onCheckedChange={() => onCheckedChange(order.id)}
                              autoSplitOn={splitAll}
                              initialConsignment={orderDispatchStore.outboundConsignments?.[0]}
                            />
                          </Card>
                        )}
                      </Card>
                    </ListItem>
                  );
                })
              : null}
            {splitAll && (
              <WarehouseAccountSelectionModal
                visible={warehouseSelectionVisible}
                warehouseAccounts={warehouseAccountStore.warehouseAccounts}
                loading={warehouseAccountStore.loading}
                error={warehouseAccountStore.error}
                onSubmit={(warehouseAccount) => {
                  warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                  setWarehouseSelectionVisible(false);
                  setOcVisible(true);
                }}
                onCancel={() => setWarehouseSelectionVisible(false)}
              />
            )}
          </ScrollView>
        </UIStatusWrapper>
      </View>
    );
  },
);

const PaperPlaneIcon = (props) => <Icon {...props} name="paper-plane" />;

const Title = observer(({ orderIdsToDispatch, orderDispatchStore, onSplitChecked }) => {
  const [splitSelected, setSplitSelected] = React.useState(undefined);

  const errors = orderDispatchStore.orders.filter(
    (_, i) => !isEmpty(orderDispatchStore.outOfStockWarehouseSkus[i]),
  );

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
    orderDispatchStore.createConsignments();
  };

  const navigation = useNavigation();

  return (
    <>
      <Layout
        style={{
          padding: '0.8em',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
          {orderIdsToDispatch?.length || 0} Order(s)
        </Text>
        <Text status="danger" style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
          {errors.length} contains error(s)
        </Text>

        <Button
          style={styles.shipAllButton}
          status="primary"
          disabled={errors.length > 0 || splitSelected === true}
          accessoryLeft={PaperPlaneIcon}
          onPress={showModal}>
          Ship
        </Button>

        <Toggle
          checked={splitSelected}
          onChange={(isChecked) => {
            setSplitSelected(isChecked);
            onSplitChecked(isChecked);
          }}>
          Split & ship
        </Toggle>
      </Layout>

      <ShippingProgressListModal
        visible={modalVisible}
        setVisible={setModalVisible}
        consignments={orderDispatchStore.orders.map((order, i) => ({
          orderNumber: order.number,
          status: orderDispatchStore.consignmentStatuses[i]?.status,
          errMsg: orderDispatchStore.consignmentStatuses[i]?.error,
          fourpxTracking: orderDispatchStore.consignmentStatuses[i]?.fourpxTracking,
        }))}
        onSelectFulfillBtn={() => {
          navigation.navigate('FulfillTable');
          setModalVisible(false);
        }}
      />
    </>
  );
});
