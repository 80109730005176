import React, { useMemo, useState, useEffect } from 'react';
import {
  Button,
  CheckBox,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Text,
} from '@ui-kitten/components';
import { sanitize } from '../core/utils/utils';
import { paperNativeTheme } from 'src/core/theme';
import { observer } from 'mobx-react';
import { courierStore, warehousesStore } from '../store';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';

export const OrderTitle = observer(
  ({
    disableAllActions,
    orderNumber,
    outOfStockWarehouseSkus,
    skuQuantity,
    unmappedStoreSkus,
    validationError,
    totalVolume,
    totalWeight,
    setCourier,
    courier,
    storeId,
    warehouseCode,
    warehouseAccountId,
    setWarehouse,
    applyCourierToAll,
    applyWarehouseToAll,
    order,
    checked,
    onCheckedChange,
  }) => {
    const [couriers, setCouriers] = useState([]);
    useEffect(() => {
      (async () => {
        // Only fetch courier list when courier can be changed
        if (courier && setCourier) {
          if (storeId) {
            const courierList = await courierStore.getStoreWhitelistedCouriersByWarehouse(
              storeId,
              warehouseCode,
              warehouseAccountId,
            );
            setCouriers(courierList);
          } else {
            const w = warehousesStore.getWarehouse(warehouseCode, warehouseAccountId);
            const courierList =
              (w && (await courierStore.getCouriers(w.warehouseAccountId, [warehouseCode]))) || [];
            setCouriers(courierList);
          }
        }
      })();
    }, [warehouseCode, warehouseAccountId, storeId]);

    const warehouseOptions = useMemo(() => {
      // Only fetch warehouse list when warehouse can be changed
      if (setWarehouse) {
        if (storeId) {
          return warehousesStore.getStoreWhitelistedWarehouse(storeId);
        } else {
          return warehousesStore.getAllStoreWhitelistedWarehouse();
        }
      }
    }, [warehousesStore.warehouses, storeId]);

    const selectedCourierIndex = useMemo(() => {
      const courierIndex = couriers.findIndex((c) => c.logistics_product_code === courier);
      return new IndexPath(courierIndex - 1 ? 0 : courierIndex);
    }, [courier, couriers]);

    const selectedWarehouseIndex = useMemo(
      () =>
        couriers.findIndex((c) => c.logistics_product_code === courier) === -1
          ? undefined
          : new IndexPath(couriers.findIndex((c) => c.logistics_product_code === courier)),
      [courier, couriers],
    );

    return (
      <Layout style={{ flexDirection: 'row' }}>
        <CheckBox checked={checked} onChange={onCheckedChange} style={{ marginRight: 8 }} />
        <Layout style={{ width: disableAllActions ? '100%' : '60%', minWidth: 400 }}>
          <Layout
            style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Text
              style={{
                width: order?.note ? '20%' : '25%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <strong>Order {orderNumber}</strong>
            </Text>
            <Text style={{ width: order?.note ? '20%' : '25%' }} category="p2">
              Total weight: {weightGramToKg(true)(totalWeight)}
            </Text>
            <Text style={{ width: order?.note ? '20%' : '25%' }} category="p2">
              Total volume: {totalVolume?.toLocaleString()}cm3
            </Text>
            <Text style={{ width: order?.note ? '20%' : '25%' }} category="p2">
              {sanitize(order?.shippingAddress?.name)} {sanitize(order?.shippingAddress?.address2)}{' '}
              {sanitize(order?.shippingAddress?.address1)}{' '}
              {sanitize(order?.shippingAddress?.provinceCode)}{' '}
              {sanitize(order?.shippingAddress?.zip)}{' '}
              {sanitize(order?.shippingAddress?.countryCode)}
            </Text>
            {order?.note ? (
              <Text category="p2" status="warning" style={{ width: '20%' }}>
                {order?.note || null}
              </Text>
            ) : null}
          </Layout>
          {unmappedStoreSkus.length > 0 ? (
            <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
              <Text category="label" status="warning" style={{ marginRight: '0.4em' }}>
                Unmapped products:{' '}
              </Text>
              <Text
                status="basic"
                category="p2"
                style={{ marginRight: '0.4em', maxWidth: 'calc(100% - 160px)' }}>
                {unmappedStoreSkus.join(',  ')}
              </Text>
            </Layout>
          ) : null}
          {skuQuantity.length === 0 ? (
            <Text category="p2" status="danger" style={{ marginTop: '0.4em' }}>
              No shippable warehouse SKU
            </Text>
          ) : (
            <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
              <Text category="label" status="info">
                Fulfillable items:{' '}
              </Text>
              <Text
                status="basic"
                category="p2"
                style={{ marginRight: '0.4em', maxWidth: 'calc(100% - 160px)' }}>
                {skuQuantity.map((s) => `${s.warehouseSku} * ${s.qty}`).join(',  ')}
              </Text>
            </Layout>
          )}
          {outOfStockWarehouseSkus.length > 0 ? (
            <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
              <Text category="label" status="danger" style={{ marginRight: '0.4em' }}>
                Out of stock:{' '}
              </Text>
              <Text
                status="basic"
                category="p2"
                style={{ marginRight: '0.4em', maxWidth: 'calc(100% - 160px)' }}>
                {outOfStockWarehouseSkus.join(',  ')}
              </Text>
            </Layout>
          ) : null}
          {validationError ? (
            <Text category="p2" status="danger" style={{ marginTop: '0.4em' }}>
              Error: {validationError}
            </Text>
          ) : null}
        </Layout>

        {!disableAllActions && (courier || setCourier) ? (
          <Select
            style={{
              width: '20%',
              minWidth: 250,
              paddingHorizontal: '0.2em',
            }}
            label="Courier"
            caption="Select a courier"
            selectedIndex={selectedCourierIndex}
            size="small"
            value={
              couriers.find((c) => c.logistics_product_code === courier)
                ?.logistics_product_name_en || courier
            }
            selectionColor={paperNativeTheme.colors.primary}
            onSelect={(index) => setCourier(couriers[index.row].logistics_product_code)}
            accessoryLeft={() =>
              applyCourierToAll ? (
                <Button
                  status="primary"
                  appearance="outline"
                  size="tiny"
                  onPress={() => applyCourierToAll(courier)}>
                  Apply to all
                </Button>
              ) : null
            }>
            {couriers.map((c) => (
              <SelectItem title={c.logistics_product_name_en} key={c.logistics_product_code} />
            ))}
          </Select>
        ) : null}
        {!disableAllActions && (warehouseCode || setWarehouse) ? (
          <Select
            style={{
              width: '20%',
              minWidth: 250,
              paddingHorizontal: '0.2em',
            }}
            label="Warehouse"
            caption="Select a warehouse"
            selectedIndex={selectedWarehouseIndex}
            size="small"
            value={warehousesStore.getWarehouseNameEn(warehouseCode, warehouseAccountId)}
            selectionColor={paperNativeTheme.colors.primary}
            onSelect={(index) => {
              setWarehouse(warehouseOptions[index.row - 1]);
            }}
            accessoryLeft={() =>
              applyWarehouseToAll ? (
                <Button
                  status="primary"
                  appearance="outline"
                  size="tiny"
                  onPress={() => {
                    applyWarehouseToAll(
                      warehouseOptions.find(
                        (w) =>
                          w.warehouse_code === warehouseCode &&
                          w.warehouseAccountId === warehouseAccountId,
                      ),
                    );
                  }}>
                  Apply to all
                </Button>
              ) : null
            }>
            <SelectItem
              disabled
              title={`Warehouse Info (Format: Warehouse ID - Account Name | Code - Warehouse Name)`}
              key={'Warehouse_Info'}
            />
            {warehouseOptions.map((c) => (
              <SelectItem
                title={`(${c.warehouseAccountId} - ${c.warehouseAccountName}) ${c.warehouse_code} - ${c.warehouse_name_en}`}
                key={c.warehouse_code}
              />
            ))}
          </Select>
        ) : null}
      </Layout>
    );
  },
);
