import { makeAutoObservable } from 'mobx';
import { sum } from 'ramda';
import { unixMillisecondToDate } from '../core/utils/utils';
import { TC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { gql } from '@apollo/client';

const DEFAUL_PARAMS = {
  create_time_start: undefined,
  create_time_end: undefined,
  logistics_product_code: undefined,
  warehouse_code: undefined,
  status: undefined,
  customer_code: undefined,
  consignmentNumber: undefined,
  refNumber: undefined,
};

const TRANSFER_ORDER_QUERY = gql`
  query transfers(
    $warehouseAccountIds: [ID!]!
    $consignmentNumbers: [ID!]
    $refNumbers: [ID!]
    $create_time_start: Float
    $create_time_end: Float
    $status: TransferStatus
    $page_no: Int
    $page_size: Int
    $customer_code: String
    $from_warehouse_code: ID
    $to_warehouse_code: ID
  ) {
    transfers(
      warehouseAccountIds: $warehouseAccountIds
      consignmentNumbers: $consignmentNumbers
      refNumbers: $refNumbers
      create_time_start: $create_time_start
      create_time_end: $create_time_end
      status: $status
      page_no: $page_no
      page_size: $page_size
      customer_code: $customer_code
      from_warehouse_code: $from_warehouse_code
      to_warehouse_code: $to_warehouse_code
    ) {
      consignments {
        warehouseAccountId
        consignment_no
        ref_no
        from_warehouse_code
        to_warehouse_code
        status
        total_volume
        total_weight
        transport_type
        shipping_no
        remark
        create_time
        lstsku {
          sku_id
          sku_code
          plan_qty
          putaway_qty
        }
      }
      total
      page_no
      page_size
    }
  }
`;

export class TransferOrderTableStore {
  searchOpen = false;
  consignmentFormOpen = false;
  filterParams = DEFAUL_PARAMS;
  ordersToCancel = new Set();
  cancelDialogueOpen = false;
  sortOption = [{ key: 'create_time', direction: 'descending' }];
  searchTerm = '';
  appointmentFormOpen = false;
  selectedIds = new Set();
  currentPage = 0;
  pageSize = 20;
  total = null;
  loading = true;
  error = null;
  cachedItems = null;
  exportedItems = null; // Store items for exporting csv
  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};

  DISPLAY_KEYS = [
    'consignment_no',
    'ref_no',
    'status',
    'from_warehouse_code',
    'to_warehouse_code',
    'numOfItems',
    'total_weight',
    'total_volume',
    'create_time',
  ];

  TITLE_BY_KEY = {
    consignment_no: 'Consignment No.',
    ref_no: 'Customer Order No.',
    status: 'Status',
    from_warehouse_code: 'From',
    to_warehouse_code: 'To',
    numOfItems: 'SKU Qty',
    total_weight: 'Weight (kg)',
    total_volume: 'Volume (m³)',
    create_time: 'Create Time',
  };

  FORMATTER_BY_KEY = {
    create_time: unixMillisecondToDate,
    status: (s) => TC_STATUS_MAP[s],
    to_warehouse_code: (w) =>
      this.warehousesStore.getWarehouseNameEn(
        w,
        this.warehouseAccountStore.selectedWarehouseAccount,
      ),
    from_warehouse_code: (w) =>
      this.warehousesStore.getWarehouseNameEn(
        w,
        this.warehouseAccountStore.selectedWarehouseAccount,
      ),
  };

  constructor(client, transferOrderStore, warehousesStore, warehouseAccountStore) {
    this.client = client;
    this.dataStore = transferOrderStore;
    this.warehousesStore = warehousesStore;
    this.warehouseAccountStore = warehouseAccountStore;

    makeAutoObservable(
      this,
      {
        dataStore: false,
        client: false,
        warehousesStore: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  openSearch = () => {
    this.searchOpen = true;
  };

  closeSearch = () => {
    this.searchOpen = false;
  };

  openConsignmentForm = () => {
    this.consignmentFormOpen = true;
  };

  closeConsignmentForm = () => {
    this.consignmentFormOpen = false;
  };

  closeCancelDialogue = () => {
    this.cancelDialogueOpen = false;
  };

  openCancelDialogue = () => {
    this.cancelDialogueOpen = true;
  };

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.cachedItems = null;
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.cachedItems = null;
  };

  setSelectedIds = (selectedIds) => {
    this.selectedIds = selectedIds;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
  };

  async fetchItems(forExport = false) {
    try {
      this.loading = true;
      if (this.searchTerm) {
        let total = 0;
        let consignments = [];
        for await (let idField of ['consignmentNumbers', 'refNumbers']) {
          const { data } = await this.client.query({
            query: TRANSFER_ORDER_QUERY,
            variables: {
              ...this.filterParams,
              create_time_start: this.filterParams.create_time_start?.getTime(),
              create_time_end: this.filterParams.create_time_end?.getTime(),
              [idField]: [this.searchTerm],
              warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
            },
            fetchPolicy: 'no-cache',
          });
          consignments = consignments.concat(data.transfers.consignments);
          total += data.transfers.total;
        }

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = consignments;
        else this.cachedItems = consignments;

        this.total = total;
      } else {
        let variables = {
          ...this.filterParams,
          consignmentNumbers: this.filterParams?.consignmentNumber
            ? [this.filterParams.consignmentNumber]
            : undefined,
          refNumbers: this.filterParams?.refNumber ? [this.filterParams.refNumber] : undefined,
          create_time_start: this.filterParams.create_time_start?.getTime(),
          create_time_end: this.filterParams.create_time_end?.getTime(),
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        };

        if (!forExport) {
          variables.page_size = this.pageSize;
          variables.page_no = this.currentPage + 1;
        }

        const {
          data: { transfers },
        } = await this.client.query({
          query: TRANSFER_ORDER_QUERY,
          variables,
          fetchPolicy: 'no-cache',
        });

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = transfers.consignments;
        else this.cachedItems = transfers.consignments;

        this.total = transfers.total;
      }
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return (this.cachedItems || []).map((tc) => ({
      ...tc,
      numOfItems: sum(tc.lstsku.map((sku) => sku.plan_qty)),
    }));
  }

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };
}
