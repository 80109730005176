import React, { useState, useEffect } from 'react';
import { NavigationBarMenuButton } from 'src/components/NavigationBarMenuButton';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { Icon, Button } from '@ui-kitten/components';
import { useKeycloak } from '@react-keycloak/web';
import { ezTheme } from '../core/theme';

export default ({ isAccountManager, user }) => {
  const { keycloak } = useKeycloak();
  const navigation = useNavigation();

  const [username, setUsername] = useState('');

  useEffect(async () => {
    const profile = await keycloak.loadUserProfile();
    setUsername(`${profile?.firstName || ''} ${profile?.lastName || ''}`);
  });

  return (
    <View style={styles.container}>
      {isAccountManager && (
        <Button
          status="warning"
          appearance="ghost"
          onPress={() => navigation.navigate('OrgListScreen')}
          accessoryRight={(props) => <Icon {...props} name="people-outline" />}
        />
      )}
      <NavigationBarMenuButton style={styles.layout} user={user} username={username} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  layout: { alignSelf: 'center' },
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },
});
