import { makeAutoObservable } from 'mobx';
import i18n from '../translations/i18n';

export class LanguageStore {
  selectedLanguage = localStorage.getItem('lang') || i18n.language;

  constructor() {
    makeAutoObservable(this);
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.setSelectedLanguage(storedLang);
    }
  }

  setSelectedLanguage = (language) => {
    this.selectedLanguage = language;
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };
}
