import React from 'react';
import { observer } from 'mobx-react';
import { StyleSheet } from 'react-native';
import Select from './Select';
import { languageStore } from '../store';

const LANGUAGES = {
  en: {
    short: 'ENG',
    full: 'English',
  },
  zh: {
    short: 'ZH',
    full: '中文',
  },
};

const LanguageDropdownSelection = observer(() => {
  const formatOption = (lang) => `${lang.short} - ${lang.full}`;

  return (
    <>
      <Select
        style={styles.container}
        options={Object.values(LANGUAGES).map(formatOption)}
        value={LANGUAGES[languageStore.selectedLanguage].short}
        onChange={(selectedLanguage) => {
          const langCode = Object.entries(LANGUAGES).find(
            ([_, lang]) => formatOption(lang) === selectedLanguage,
          )[0];
          languageStore.setSelectedLanguage(langCode);
        }}
      />
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    minWidth: '5rem',
    backgroundColor: 'transparent',
  },
});

export default LanguageDropdownSelection;
