import { Menu } from 'react-native-paper';
import React from 'react';
import { removeAuthToken } from '../core/cache';
import { useNavigation } from '@react-navigation/native';
import { useKeycloak } from '@react-keycloak/web';
import { Linking, View, StyleSheet } from 'react-native';
import { Button, Icon, Card, Modal, Text } from '@ui-kitten/components';
import LanguageDropdownSelection from './LanguageDropdownSelection';

export const NavigationBarMenuButton = ({ user, username }) => {
  const { keycloak } = useKeycloak();

  const [visible, setVisible] = React.useState(false);
  const [languageModalVisible, setLanguageModalVisible] = React.useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const navigation = useNavigation();

  return (
    <>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <View style={styles.accountMenu}>
            <Button
              onPress={openMenu}
              style={{ textTransform: 'capitalize' }}
              accessoryRight={(props) => (
                <Icon {...props} fill="white" name="person-outline"></Icon>
              )}>
              {username}
            </Button>
          </View>
        }>
        <Menu.Item
          icon="bank"
          onPress={() => {
            navigation.navigate('UserAccountBalanceScreen');
            closeMenu();
          }}
          title="Account balance"
        />

        <Menu.Item
          icon="account-supervisor-circle"
          onPress={() => {
            navigation.navigate('OrgProfileScreen');
            closeMenu();
          }}
          title="Your organisation"
        />

        <Menu.Item
          icon="account-circle"
          onPress={() => {
            const url = keycloak.createAccountUrl();
            Linking.canOpenURL(url).then((supported) => {
              if (supported) {
                Linking.openURL(url);
              } else {
                console.log("Don't know how to open URI: " + url);
              }
            });
          }}
          title="Account management"
        />

        <Menu.Item
          icon="translate"
          onPress={() => {
            setLanguageModalVisible(true);
            closeMenu();
          }}
          title="Language"
        />

        <Menu.Item
          icon="help-circle-outline"
          onPress={async () => {
            if (window) {
              // <!-- Download Canny SDK in web environment-->
              eval(
                '!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");',
              );
              const userInfo = await keycloak.loadUserInfo();
              // Use the Canny SDK to identify the current user of your website
              Canny('identify', {
                appID: '62ff65652c7f9e44d345105c',
                user: {
                  companies: [
                    {
                      id: user.organisation.id,
                      name: user.organisation.name,
                    },
                  ],
                  email: userInfo.email,
                  id: userInfo.sub,
                  name: userInfo.name,
                },
              });
            }
            const url = 'https://community.ezom.app/feature-requests';
            if (window.open) {
              window.open(url, '_blank');
            } else {
              Linking.openURL(url);
            }
          }}
          title="Feature request"
        />

        <Menu.Item
          icon="logout"
          onPress={() => {
            removeAuthToken();
            keycloak.logout();
            closeMenu();
          }}
          title="Log out"
        />
      </Menu>
      <Modal
        visible={languageModalVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setLanguageModalVisible(false)}>
        <Card
          style={styles.card}
          header={(props) => (
            <View {...props}>
              <Text category="h6">Select Language</Text>
            </View>
          )}
          footer={(footerProps) => (
            <View {...footerProps}>
              <Button
                style={styles.footerControl}
                size="small"
                onPress={() => setLanguageModalVisible(false)}>
                Close
              </Button>
            </View>
          )}>
          <LanguageDropdownSelection />
        </Card>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  accountMenu: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  card: {
    flex: 1,
    margin: 2,
    minWidth: 300,
    maxWidth: '90%',
  },
  footerControl: {
    marginTop: '0.5em',
  },
});
